body {
    font-size: 2em;
}

label {
    display: block;
    margin-bottom: 10px;
  }
  
  .chat {
    margin-top: 20px;
  }
  
  .chat-input-wrapper {
    display: flex;
  }
  
  #chat-input {
    flex: 1;
    margin-right: 10px;
  }
  
  #send-btn {
    width: 80px;
  }
  
  .online-users {
    margin-top: 20px;
  }
  
  .online-users h2 {
    display: inline-block;
    margin-right: 10px;
  }
  
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .user .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .user .dot.online {
    background-color: green;
  }
  

/* Responsive layout */
@media only screen and (min-width: 480px) {
    #users {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    #users li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
    }
  
    #users li::before {
      content: '';
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      border-radius: 50%;
      background-color: #35A654;
    }
  
    #chat-list {
      height: 200px;
      overflow: auto;
    }
  }
  
  /* General styling */
  #chat-list li {
    white-space: pre-wrap;
  }
  
  /* User list styling */
  #users {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #users li {
    margin-bottom: 0.5em;
  }
  
  /* Chat list styling */
  #chat-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Chat input styling */
  #chat-input {
    width: 100%;
    margin-top: 0.5em;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  