body {
  font-size: 2em;
}

label {
  margin-bottom: 10px;
  display: block;
}

.chat {
  margin-top: 20px;
}

.chat-input-wrapper {
  display: flex;
}

#chat-input {
  flex: 1;
  margin-right: 10px;
}

#send-btn {
  width: 80px;
}

.online-users {
  margin-top: 20px;
}

.online-users h2 {
  margin-right: 10px;
  display: inline-block;
}

.user {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.user .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.user .dot.online {
  background-color: green;
}

@media only screen and (width >= 480px) {
  #users {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  #users li {
    align-items: center;
    margin-bottom: .5em;
    display: flex;
  }

  #users li:before {
    content: "";
    width: .5em;
    height: .5em;
    background-color: #35a654;
    border-radius: 50%;
    margin-right: .5em;
    display: inline-block;
  }

  #chat-list {
    height: 200px;
    overflow: auto;
  }
}

#chat-list li {
  white-space: pre-wrap;
}

#users {
  margin: 0;
  padding: 0;
  list-style: none;
}

#users li {
  margin-bottom: .5em;
}

#chat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

#chat-input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: .5em;
  padding: .5em;
}

/*# sourceMappingURL=index.326755ab.css.map */
